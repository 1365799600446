import { MenuItem, SelectChangeEvent } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import AppSelect from "../../components/form/select";
import { Column, ColumnsWrapper } from "./BasicInfo";
import AppTextField from "../../components/form/textField";
import FormController from "../../components/formController";
import { useAppSelector } from "stores/store";
import { useGetAllMerchantsQuery } from "api/endpoints/merchants";
import { useGetAllLocationsQuery } from "api/endpoints/locations";
import { useTranslation } from "react-i18next";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import FullscreenLoader from "components/fullscreenLoader";
import { useContext } from "react";
import TerminalContext from "pages/newTerminal/TerminalContext";
import StepNavigationButtons from "pages/newTerminal/StepNavigationButtons";

const NETWORK = "network";
const MERCHANT = "merchant";
const LOCATION = "location";
const ACQUIRER = "acquirerId";
const SUB_MERCHANT_ID = "sub_merchant_id";
const SIGNATURE_KEY = "signature_key";

type FormValues = {
  [NETWORK]: string;
  [MERCHANT]: string;
  [LOCATION]: string;
  [SUB_MERCHANT_ID]: string;
  [ACQUIRER]: string;
  [SIGNATURE_KEY]: string;
};

export default function AdditInfo() {
  const { t } = useTranslation("common", {
    keyPrefix: "newTerminal",
  });
  const { t: tValidation } = useTranslation("", {
    keyPrefix: "validation",
  });
  const { networkId } = useAppSelector((state) => state.user);
  const networks = [networkId];
  const { newTerminal, setNewTerminal, nextStep, activeStep, prevStep } =
    useContext(TerminalContext);

  const defaultValues = {
    [NETWORK]: newTerminal.network ?? networks[0],
    [MERCHANT]: newTerminal.merchant ?? "",
    [LOCATION]: newTerminal.location ?? "",
    [ACQUIRER]: newTerminal.acquirerId ?? "",
    [SUB_MERCHANT_ID]: newTerminal.sub_merchant_id ?? "",
    [SIGNATURE_KEY]: newTerminal.signature_key ?? "",
  };

  const schema = Joi.object({
    [NETWORK]: Joi.string().allow(""),
    [MERCHANT]: Joi.string()
      .trim()
      .required()
      .messages({
        "string.base": tValidation("textField.mustBeString"),
        "string.empty": tValidation("required"),
      }),
    [LOCATION]: Joi.string()
      .trim()
      .required()
      .messages({
        "string.base": tValidation("textField.mustBeString"),
        "string.empty": tValidation("required"),
      }),
    [ACQUIRER]: Joi.string()
      .trim()
      .required()
      .max(16)
      .messages({
        "string.base": tValidation("textField.mustBeString"),
        "string.empty": tValidation("required"),
        "string.max": tValidation("maxLength", { max: 16 }),
      }),
    [SUB_MERCHANT_ID]: Joi.string()
      .trim()
      .messages({
        "string.base": tValidation("textField.mustBeString"),
      })
      .allow(""),
    [SIGNATURE_KEY]: Joi.string()
      .trim()
      .messages({
        "string.base": tValidation("textField.mustBeString"),
      })
      .allow(""),
  });

  const form = useForm<FormValues>({
    resolver: joiResolver(schema),
    defaultValues: defaultValues,
  });
  const { control, handleSubmit, watch, setValue, trigger } = form;

  const currentMerchant = watch(MERCHANT);

  const { data: merchants, isLoading: loadingMerchants } =
    useGetAllMerchantsQuery(networkId, { limit: 1000 });
  const { data: locations, isLoading: loadingLocations } =
    useGetAllLocationsQuery(networkId, {
      limit: 1000,
      merchantId: currentMerchant,
    });

  const onSubmit = (data: FormValues) => {
    const UpdatedNewTerminal = {
      ...newTerminal,
      network: data[NETWORK],
      merchant: data[MERCHANT],
      location: data[LOCATION],
      acquirerId: data[ACQUIRER],
      sub_merchant_id: data[SUB_MERCHANT_ID],
      signature_key: data[SIGNATURE_KEY],
    };
    setNewTerminal(UpdatedNewTerminal);
    nextStep();
  };

  const handleMerchantChange = (e: SelectChangeEvent<unknown>) => {
    const merchantValue = e.target.value as string;
    setValue(MERCHANT, merchantValue);
    setValue(LOCATION, "");
    trigger(MERCHANT);
  };

  if (loadingMerchants || loadingLocations) return <FullscreenLoader />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ColumnsWrapper>
        <Column>
          <Controller
            control={control}
            name={MERCHANT}
            render={({ field: { value }, fieldState: { error } }) => (
              <AppSelect
                variant="outlined"
                name={MERCHANT}
                fullWidth
                label={t(MERCHANT)}
                required
                value={value}
                onChange={handleMerchantChange}
                error={!!error?.message}
                errorText={error?.message}
              >
                {merchants?.data.map((item, index) => (
                  <MenuItem value={item.identifier} key={index}>
                    {item.name}
                  </MenuItem>
                ))}
              </AppSelect>
            )}
          />
        </Column>
        <Column>
          <FormController name={LOCATION} control={control}>
            <AppSelect
              variant="outlined"
              name={LOCATION}
              fullWidth
              label={t(LOCATION)}
              required
            >
              {locations?.data.map((item, index) => (
                <MenuItem value={item.identifier} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </AppSelect>
          </FormController>
        </Column>
        <Column>
          <FormController name={ACQUIRER} control={control}>
            <AppTextField label={t(ACQUIRER)} required />
          </FormController>
        </Column>
        <Column>
          <FormController name={SUB_MERCHANT_ID} control={control}>
            <AppTextField label={t(SUB_MERCHANT_ID)} />
          </FormController>
        </Column>
        <Column>
          <FormController name={SIGNATURE_KEY} control={control}>
            <AppTextField label={t("terminal_mapping")} />
          </FormController>
        </Column>
      </ColumnsWrapper>
      <StepNavigationButtons activeStep={activeStep} prevStep={prevStep} />
    </form>
  );
}
