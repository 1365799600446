import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  Drawer,
  IconButton,
  MenuItem,
  SelectChangeEvent,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
  ListSubheader,
} from "@mui/material";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import { Container } from "@mui/system";
import switchioLogo from "../../assets/images/switchio_blue_small.svg";
import AppLink from "../appLink/appLink";
import { Home, Store, PinDrop, PointOfSale } from "@mui/icons-material";
import { SearchBox } from "./searchBox";
import { SimpleAppSelect } from "../form/select/simpleAppSelect";
import { UserAvatar } from "./userAvatar";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import useLanguageSelect from "../../utils/hooks/useLanguageSelect";
import { useTranslation } from "react-i18next";
import {
  DASHBOARD_URL,
  MERCHANTS_URL,
  LOCATIONS_URL,
  TERMINALS_URL,
} from "../../pages/appRoutes/appRoutesConst";
import { COLORS } from "theme/colors";
import useAuthentication from "utils/hooks/useAuthentication";
import { UserRole } from "types/commonTypes";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import FullscreenLoader from "components/fullscreenLoader";
import { UserAppsMenu } from "components/layouts/userAppsMenu";

const DRAWER_WIDTH = 430;
const DRAWER_WIDTH_COLLAPSED = 104;
export const HEADER_HEIGHT = 100;

const AppWrapper = styled("div")(({ theme: { palette } }) => ({
  minHeight: "100vh",
  backgroundColor: palette.background.lightGrey.main,
  display: "flex",
  flexDirection: "column",
  fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
}));

const AppContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== "isCollapsed",
})<{ isCollapsed: boolean; isMobile: boolean }>(({ isCollapsed, isMobile }) => {
  const DRAWER_WIDTH_MAIN = isCollapsed ? DRAWER_WIDTH_COLLAPSED : DRAWER_WIDTH;

  return {
    minHeight: "calc(100vh - 128px)",
    marginLeft: `${DRAWER_WIDTH_MAIN}px`,
    width: `calc(100% - ${DRAWER_WIDTH_MAIN}px)`,
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    maxWidth: "100% !important",
    transition: "all 0.4s ease-out",
    padding: isMobile ? "0.5rem" : "5rem",
  };
});

interface Props {
  children: React.ReactNode;
}

type NavButtonProps = { isActive: boolean };

const NavButton = styled("div", {
  shouldForwardProp: (prop) => prop !== "isActive",
})<NavButtonProps>(({ theme: { palette }, isActive }) => ({
  ":first-letter": {
    textTransform: "capitalize",
  },
  textDecoration: "none",
  color: isActive ? COLORS.DARK_BLUE.MAIN : palette.text.lightGrey.main,
  background: isActive ? palette.primary.main : "unset",
  borderRadius: "10px",
  padding: "16px",
}));

const NavBar = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isCollapsed",
})<{ isCollapsed: boolean; isMobile: boolean }>(({
  theme: { spacing, palette },
  isCollapsed,
  isMobile,
}) => {
  const DRAWER_WIDTH_MAIN = isCollapsed ? DRAWER_WIDTH_COLLAPSED : DRAWER_WIDTH;
  return {
    position: "sticky",
    display: "flex",
    top: 0,
    padding: isMobile ? spacing(1, 2) : spacing(2, 6),
    backgroundColor: palette.common.white,
    zIndex: 10,
    width: `calc(100% - ${DRAWER_WIDTH_MAIN}px)`,
    transition: "all 0.4s ease-out",
    marginLeft: `${DRAWER_WIDTH_MAIN}px`,
    minHeight: `${HEADER_HEIGHT}px`,
  };
});

const HelpAndFeedback = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isCollapsed",
})<{ isCollapsed: boolean }>(
  ({ theme: { spacing, typography }, isCollapsed }) => ({
    display: "flex",
    justifyContent: isCollapsed ? "center" : "start",
    gap: spacing(2),
    marginTop: "auto",
    margin: isCollapsed ? spacing("auto", "auto", 3) : spacing("auto", 0, 3),
    fontWeight: typography.fontWeightMedium,
    height: spacing(3),
    padding: isCollapsed ? spacing(0, 4, 0, 5) : spacing(0, 4),
    transition: "all 0.4s ease-out",
    color: COLORS.DARK_BLUE.MAIN,
  }),
);

// const HelpAndFeedbackText = styled(Box, {
//   shouldForwardProp: (prop) => prop !== "isCollapsed",
// })<{ isCollapsed: boolean }>(({ isCollapsed }) => ({
//   color: isCollapsed ? "transparent" : COLORS.DARK_BLUE.MAIN,
//   whiteSpace: "nowrap",
// }));

const DrawerFooter = styled("div", {
  shouldForwardProp: (prop) => prop !== "isCollapsed",
})<{ isCollapsed: boolean }>(({ theme: { spacing }, isCollapsed }) => ({
  width: "100%",
  backgroundColor: COLORS.LIGHT_BLUE.LIGHT,
  padding: spacing(isCollapsed ? 2 : 4.25),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  minHeight: spacing(12.5),
  columnGap: spacing(1.25),
  alignItems: "start",
}));

const DrawerFooterHeading = styled("div")(
  ({ theme: { spacing, palette, typography } }) => ({
    color: palette.secondary.main,
    fontWeight: typography.fontWeightBold,
    fontSize: spacing(4),
  }),
);

const DrawerFooterContentWrapper = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  gap: spacing(2),
}));

const ConfirmationNumberIconWrapper = styled("div")(
  ({ theme: { spacing, palette } }) => ({
    backgroundColor: palette.terniary.main,
    padding: spacing(0.625),
    borderRadius: spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
);

const StyledIconButton = styled(IconButton)(
  ({ theme: { spacing, palette } }) => ({
    width: spacing(4),
    height: spacing(4),
    color: palette.common.white,
    backgroundColor: COLORS.GREY.LIGHT,
    "&:hover": {
      backgroundColor: palette.text.lightGrey.dark,
    },
  }),
);

const StyledConfirmationNumberOutlinedIcon = styled(
  ConfirmationNumberOutlinedIcon,
)(({ theme: { palette } }) => ({
  color: palette.common.white,
}));

const LogoLinkWrapper = styled(AppLink, {
  shouldForwardProp: (prop) => prop !== "isCollapsed",
})<{ isCollapsed: boolean }>(({ theme: { spacing }, isCollapsed }) => ({
  display: "flex",
  padding: isCollapsed ? spacing(4, 2) : spacing(2, 4, 2),
  flexDirection: "column",
  borderBottom: `1px solid ${COLORS.LIGHT_GREY.DARK}`,
  minHeight: `calc(${HEADER_HEIGHT}px + 1px)`,
  justifyContent: "center",
}));

const NavItemsWrapper = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  flexWrap: "wrap",
  paddingTop: spacing(6),
}));

const NAVBAR_FIELDS = [
  { label: "dashboard", url: DASHBOARD_URL, Icon: <Home /> },
  { label: "merchants", url: MERCHANTS_URL, Icon: <Store /> },
  { label: "locations", url: LOCATIONS_URL, Icon: <PinDrop /> },
  { label: "terminals", url: TERMINALS_URL, Icon: <PointOfSale /> },
  // { lable: "hardware", url: HARDWARE_URL, Icon: <Power /> },
];

const AuthenticatedLayout = ({ children }: Props) => {
  const theme = useTheme();
  const { changeRole, userGroups, currentRole, currentUserGroup } =
    useAuthentication();
  const logoUrl = currentUserGroup?.logo;
  const [isCollapsed, setIsCollapsed] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const languageSelect = useLanguageSelect({ isMobile });
  const [roleId, setRoleId] = useState(currentRole?.id ?? "");

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    changeRole(e.target.value as string);
    setRoleId(e.target.value as string);
  };

  const { t } = useTranslation("common", { keyPrefix: "layout" });

  LicenseInfo.setLicenseKey(window.appConfig.REACT_APP_MUIX_LICENSE_KEY ?? "");

  useEffect(() => {
    if (isTablet) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  }, [isTablet]);

  const DRAWER_WIDTH_MAIN = isCollapsed ? DRAWER_WIDTH_COLLAPSED : DRAWER_WIDTH;

  if (!currentRole) {
    return <FullscreenLoader />;
  }

  return (
    <AppWrapper>
      <Drawer
        sx={{
          width: DRAWER_WIDTH_MAIN,
          flexShrink: 0,
          position: "relative",
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH_MAIN,
            transition: "all 0.4s ease-out",
            boxSizing: "border-box",
            overflow: "hidden",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <LogoLinkWrapper to="/" isCollapsed={isCollapsed}>
          {isCollapsed ? (
            <>
              <img src={logoUrl ?? switchioLogo} alt="switchio logo" />
            </>
          ) : (
            <>
              <img
                src={logoUrl ?? switchioLogo}
                width={190}
                alt="switchio logo"
              />
            </>
          )}
        </LogoLinkWrapper>
        <Container>
          <NavItemsWrapper>
            {NAVBAR_FIELDS.map(({ label, url, Icon }, index) => (
              <NavLink
                style={{
                  textDecoration: "none",
                  display: "block",
                }}
                to={url}
                key={index}
              >
                {({ isActive }) => {
                  return (
                    <NavButton isActive={isActive}>
                      <div
                        style={{
                          display: "flex",
                          gap: "2rem",
                          justifyContent: isCollapsed ? "center" : "start",
                        }}
                      >
                        {Icon}
                        {!isCollapsed && <Typography>{t(label)}</Typography>}
                      </div>
                    </NavButton>
                  );
                }}
              </NavLink>
            ))}
          </NavItemsWrapper>
        </Container>
        <HelpAndFeedback isCollapsed={isCollapsed}>
          {/*<InfoIcon />{" "}*/}
          {/*<HelpAndFeedbackText isCollapsed={isCollapsed}>*/}
          {/*  {t("components.avatar.helpAndFeedback")}*/}
          {/*</HelpAndFeedbackText>*/}
        </HelpAndFeedback>
        <DrawerFooter isCollapsed={isCollapsed}>
          {!isCollapsed ? (
            <>
              {!logoUrl && <img width={82} height={14} src={switchioLogo} />}
              <DrawerFooterContentWrapper>
                <DrawerFooterHeading>
                  {t("navigation.transportPortal")}
                </DrawerFooterHeading>
                <StyledIconButton
                  onClick={() => {
                    setIsCollapsed(!isCollapsed);
                  }}
                >
                  <ExpandLessIcon style={{ transform: "rotate(270deg)" }} />{" "}
                </StyledIconButton>
              </DrawerFooterContentWrapper>
            </>
          ) : (
            <DrawerFooterContentWrapper>
              <ConfirmationNumberIconWrapper>
                <StyledConfirmationNumberOutlinedIcon />
              </ConfirmationNumberIconWrapper>
              <StyledIconButton
                onClick={() => {
                  setIsCollapsed(!isCollapsed);
                }}
              >
                <ExpandLessIcon style={{ transform: "rotate(90deg)" }} />{" "}
              </StyledIconButton>
            </DrawerFooterContentWrapper>
          )}
        </DrawerFooter>
      </Drawer>
      <NavBar isCollapsed={isCollapsed} isMobile={isMobile}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            paddingRight: "1.5rem",
            flexDirection: isTablet ? "column" : "row",
            gap: 2,
          }}
        >
          <SearchBox />
          <Box display={"flex"} gap={isMobile ? 0.5 : 2}>
            <UserAppsMenu />
            <SimpleAppSelect
              value={roleId ?? ""}
              onChange={handleChange}
              sx={{ maxWidth: isMobile ? "140px" : "100%" }}
            >
              {userGroups.map((group) => [
                <ListSubheader key={group.id}>{group.name}</ListSubheader>,
                ...group.roles.map((role: UserRole) => (
                  <MenuItem value={role.id} key={role.id}>
                    {role.name}
                  </MenuItem>
                )),
              ])}
            </SimpleAppSelect>
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "center",
                alignItems: "center",
                gap: 2.5,
              }}
            >
              {/*<NotificationBell />*/}
              {languageSelect}
              <UserAvatar />
            </Box>
          </Box>
        </Box>
      </NavBar>
      <AppContainer isCollapsed={isCollapsed} isMobile={isMobile}>
        {children}
      </AppContainer>
    </AppWrapper>
  );
};
export default AuthenticatedLayout;
