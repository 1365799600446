import { Box, Grid, styled, Typography } from "@mui/material";
import {
  activateTerminal,
  deactivateTerminal,
  permanentDeactivate,
  useGetTerminalQuery,
} from "api/endpoints/terminals";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "stores/store";
import AppButton from "../../components/appButton";
import { AppDataContainer } from "components/appDataContainer";
import BreadCrumb from "../../components/breadcrumbs/BreadCrumb";
import { TERMINAL_PAGES, TERMINALS_URL } from "../appRoutes/appRoutesConst";
import { COLORS } from "theme/colors";
import { theme } from "theme";
import { useTranslation } from "react-i18next";
import { errorHandling } from "utils/funcs/errorHandling";
import { useContext, useState } from "react";
import ErrorDialog from "components/Dialog/ErrorDialog";
import SuccessDialog from "components/Dialog/SuccessDialog";
import {
  detailQueryResult,
  TerminalDetail as TTerminalDetail,
} from "types/commonTypes";
import FullscreenLoader from "components/fullscreenLoader";
import TerminalContext from "pages/newTerminal/TerminalContext";
import Dialog from "components/Dialog";

const StyledTitleText = styled(Typography)(({ theme: { typography } }) => ({
  ...typography.body2,
  fontWeight: "400",
  color: COLORS.GREY.MAIN,
}));

const StyledValueText = styled(Typography)(({ theme: { typography } }) => ({
  ...typography.body2,
  fontWeight: "700",
  color: COLORS.GREY.MAIN,
  wordWrap: "break-word",
  maxWidth: "100%",
}));

export default function TerminalDetail() {
  const { networkId } = useAppSelector((state) => state.user);
  const { terminalId, merchantId, locationId } = useParams();
  const navigate = useNavigate();
  const { spacing } = theme;
  const [successMessage, setSuccessMessage] = useState("");
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [deactivationConfirmation, showConfirmation] = useState(false);
  const [errors, setError] = useState<string[]>();
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setActiveStep } = useContext(TerminalContext);
  const { t } = useTranslation("common", { keyPrefix: "terminalDetail" });
  const { t: tStatus } = useTranslation("common", {
    keyPrefix: "enums.ImportStatus",
  });
  const handleClose = () => showConfirmation(false);

  if (!terminalId || !merchantId || !locationId) return null;

  const { data, isLoading: terminalLoading } = useGetTerminalQuery(
    networkId,
    merchantId,
    locationId,
    terminalId,
  ) as detailQueryResult<TTerminalDetail>;

  const updateDataHandler = () => {
    setActiveStep(0);
    navigate(
      `/${TERMINALS_URL}/${TERMINAL_PAGES.EDIT_TERMINAL}/${merchantId}/${locationId}/${terminalId}`,
    );
  };

  const duplicateDataHandler = () => {
    setActiveStep(0);
    navigate(
      `/${TERMINALS_URL}/${TERMINAL_PAGES.DUPLICATE_TERMINAL}/${merchantId}/${locationId}/${terminalId}`,
    );
  };
  const activateTerminalHandler = async () => {
    setIsLoading(true);
    await activateTerminal(networkId, locationId, merchantId, terminalId)
      .then(() => {
        setSuccessMessage(t("terminalActivated"));
        setIsSuccessDialogOpen(true);
      })
      .catch((error) => {
        errorHandling(error, setError, setErrorDialogOpen);
      })
      .finally(() => setIsLoading(false));
  };
  const deactivateTerminalHandler = async () => {
    setIsLoading(true);
    await deactivateTerminal(networkId, locationId, merchantId, terminalId)
      .then(() => {
        setSuccessMessage(t("terminalDeactivated"));
        setIsSuccessDialogOpen(true);
      })
      .catch((error) => {
        errorHandling(error, setError, setErrorDialogOpen);
      })
      .finally(() => setIsLoading(false));
  };

  const permanentDeactivateHandler = async () => {
    setIsLoading(true);
    await permanentDeactivate(networkId, locationId, merchantId, terminalId)
      .then(() => {
        setSuccessMessage(t("permanentlyDeactivated"));
        setIsSuccessDialogOpen(true);
      })
      .catch((error) => {
        errorHandling(error, setError, setErrorDialogOpen);
      })
      .finally(() => {
        showConfirmation(false);
        setIsLoading(false);
      });
  };

  const BUTTONS = [
    <AppButton
      variant="contained"
      color="secondary"
      size="small"
      key={0}
      onClick={updateDataHandler}
    >
      {t("editBtn")}
    </AppButton>,
    <AppButton
      variant="contained"
      color="secondary"
      size="small"
      key={1}
      onClick={duplicateDataHandler}
    >
      {t("duplicateBtn")}
    </AppButton>,
  ];

  if (data?.status === "C") {
    BUTTONS.push(
      <AppButton
        variant="contained"
        color="success"
        size="small"
        key={2}
        onClick={activateTerminalHandler}
      >
        {t("activateBtn")}
      </AppButton>,
    );
  } else if (data?.status === "A") {
    BUTTONS.push(
      <AppButton
        variant="contained"
        color="error"
        size="small"
        key={2}
        onClick={deactivateTerminalHandler}
      >
        {t("deactivateBtn")}
      </AppButton>,
    );
  }
  BUTTONS.push(
    <AppButton
      variant="contained"
      color="error"
      size="small"
      key={3}
      onClick={() => showConfirmation(true)}
    >
      {t("permanentDeactivation")}
    </AppButton>,
  );

  if (isLoading || terminalLoading) return <FullscreenLoader />;

  if (!data) return null;

  return (
    <>
      <BreadCrumb
        items={[
          { title: t("breadCrumbTitle"), linkTo: TERMINALS_URL },
          { title: `Terminal ${data.identifier} (${data.name})` },
        ]}
        subtitle={data.wiped_out ? t("permanently_canceled") : undefined}
      />
      <Box
        sx={{
          marginBottom: 3,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <AppDataContainer
          sx={{ marginBottom: 3 }}
          titleRow={{
            title: t("basicInfoSectionTitle"),
            buttons: !data.wiped_out ? BUTTONS : undefined,
          }}
        >
          <Grid
            container
            spacing={{ xs: 2, md: 4 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            padding={spacing(4, 3)}
          >
            <Grid item xs={2} sm={4} md={2}>
              <StyledTitleText>{t("terminalID")}</StyledTitleText>
              <StyledValueText>{data.identifier}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={3}>
              <StyledTitleText>{t("terminalName")}</StyledTitleText>
              <StyledValueText>{data.name}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={2}>
              <StyledTitleText>{t("status")}</StyledTitleText>
              <StyledValueText>{tStatus(data.status)}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={2}>
              <StyledTitleText>{t("authorizationStatus")}</StyledTitleText>
              <StyledValueText>{tStatus(data.auth_status)}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={2}>
              <StyledTitleText>{t("acquirerId")}</StyledTitleText>
              <StyledValueText>{data.acquirer_id}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={2}>
              <StyledTitleText>{t("parameterState")}</StyledTitleText>
              <StyledValueText>{data.parameter_status}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={3}>
              <StyledTitleText>{t("lastCallStatus")}</StyledTitleText>
              <StyledValueText>{data.last_call_status}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={2}>
              <StyledTitleText>{t("lastSuccessCall")}</StyledTitleText>
              <StyledValueText>{data.last_success_call_store}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={2}>
              <StyledTitleText>{t("lastErrorCall")}</StyledTitleText>
              <StyledValueText>{data.last_error_call}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={2}>
              <StyledTitleText>{t("profileName")}</StyledTitleText>
              <StyledValueText>{data.profile}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={2}>
              <StyledTitleText>{t("created")}</StyledTitleText>
              <StyledValueText>{data.created}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={3}>
              <StyledTitleText>{t("updated")}</StyledTitleText>
              <StyledValueText>{data.updated}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <StyledTitleText>{t("address")}</StyledTitleText>
              <StyledValueText>{`${data.street}, ${data.city}, ${data.zip_code}`}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={12}>
              <StyledTitleText>{t("note")}</StyledTitleText>
              <StyledValueText>{data.note}</StyledValueText>
            </Grid>
          </Grid>
        </AppDataContainer>
        <AppDataContainer
          sx={{ marginBottom: 3 }}
          titleRow={{
            title: t("addInfo"),
          }}
        >
          <Grid
            container
            spacing={{ xs: 2, md: 4 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            padding={spacing(4, 3)}
          >
            <Grid item xs={2} sm={4} md={12}>
              <StyledTitleText>{t("network")}</StyledTitleText>
              <StyledValueText>{data.network.identifier}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={3}>
              <StyledTitleText>{t("merchantId")}</StyledTitleText>
              <StyledValueText>{data.merchant.identifier}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={3}>
              <StyledTitleText>{t("merchantName")}</StyledTitleText>
              <StyledValueText>{data.merchant.name}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={5}>
              <StyledTitleText>{t("merchantAddress")}</StyledTitleText>
              <StyledValueText>{`${data.merchant.street}, ${data.merchant.city}, ${data.merchant.zip_code}`}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={3}>
              <StyledTitleText>{t("locationId")}</StyledTitleText>
              <StyledValueText>{data.location.identifier}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={3}>
              <StyledTitleText>{t("locationName")}</StyledTitleText>
              <StyledValueText>{data.location.name}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={5}>
              <StyledTitleText>{t("locationAddress")}</StyledTitleText>
              <StyledValueText>{`${data.location.street}, ${data.location.city}, ${data.location.zip_code}`}</StyledValueText>
            </Grid>
          </Grid>
        </AppDataContainer>
        <AppDataContainer
          sx={{ marginBottom: 3 }}
          titleRow={{
            title: t("settings"),
          }}
        >
          <Grid
            container
            spacing={{ xs: 2, md: 4 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            padding={spacing(4, 3)}
          >
            <Grid item xs={2} sm={4} md={2}>
              <StyledTitleText>{t("dcc")}</StyledTitleText>
              <StyledValueText>{data.dcc}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={3}>
              <StyledTitleText>{t("tokenization")}</StyledTitleText>
              <StyledValueText>{data.tokenization}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={6}>
              <StyledTitleText>{t("transportMode")}</StyledTitleText>
              <StyledValueText>{data.transport_mode}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={2}>
              <StyledTitleText>{t("cardCapture")}</StyledTitleText>
              <StyledValueText>{data.card_capture}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={3}>
              <StyledTitleText>{t("cardData")}</StyledTitleText>
              <StyledValueText>{data.card_data_input}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={3}>
              <StyledTitleText>{t("cardHolder")}</StyledTitleText>
              <StyledValueText>{data.card_holder_auth}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={2}>
              <StyledTitleText>{t("pinCapture")}</StyledTitleText>
              {/*<StyledValueText>{data.pin_capture}</StyledValueText>*/}
            </Grid>
            <Grid item xs={2} sm={4} md={2}>
              <StyledTitleText>{t("dataOutput")}</StyledTitleText>
              <StyledValueText>{data.data_output}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={2}>
              <StyledTitleText>{t("virtualisation")}</StyledTitleText>
              <StyledValueText>{data.virtualisation}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={3}>
              <StyledTitleText>{t("isMother")}</StyledTitleText>
              <StyledValueText>{data.is_mother}</StyledValueText>
            </Grid>
            <Grid item xs={2} sm={4} md={3}>
              <StyledTitleText>{t("name")}</StyledTitleText>
              <StyledValueText>{data.name}</StyledValueText>
            </Grid>
          </Grid>
        </AppDataContainer>
        {/*<Typography variant="h4">{t("terminalApps")}</Typography>*/}
        {/*<AppDataContainer*/}
        {/*  sx={{ marginBottom: 3 }}*/}
        {/*  titleRow={{*/}
        {/*    title: "Switchio Payments",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>*/}
        {/*    <MultipleColumnContainer*/}
        {/*      //   renderData={customRenderFunctions(tEnums)}*/}
        {/*      columns={[*/}
        {/*        ["Network"],*/}
        {/*        [*/}
        {/*          "Parametr state",*/}
        {/*          "Last call state",*/}
        {/*          "Last success call",*/}
        {/*          "Last error call",*/}
        {/*          "Profile name",*/}
        {/*        ],*/}
        {/*      ]}*/}
        {/*      dataObj={{}}*/}
        {/*      translateFunc={(k: string): string => {*/}
        {/*        return k;*/}
        {/*        // return t(k);*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </Box>*/}
        {/*</AppDataContainer>*/}
        {/*<AppDataContainer*/}
        {/*  sx={{ marginBottom: 3 }}*/}
        {/*  titleRow={{*/}
        {/*    title: "Switchio Marketplace",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>*/}
        {/*    <MultipleColumnContainer*/}
        {/*      //   renderData={customRenderFunctions(tEnums)}*/}
        {/*      columns={[*/}
        {/*        ["Network"],*/}
        {/*        [*/}
        {/*          "Parametr state",*/}
        {/*          "Last call state",*/}
        {/*          "Last success call",*/}
        {/*          "Last error call",*/}
        {/*          "Profile name",*/}
        {/*        ],*/}
        {/*      ]}*/}
        {/*      dataObj={{}}*/}
        {/*      translateFunc={(k: string): string => {*/}
        {/*        return k;*/}
        {/*        // return t(k);*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </Box>*/}
        {/*</AppDataContainer>*/}
      </Box>
      <ErrorDialog
        isDialogOpen={errorDialogOpen}
        handleClose={() => setErrorDialogOpen(false)}
        messages={errors}
      />
      <SuccessDialog
        isDialogOpen={isSuccessDialogOpen}
        handleClose={() => setIsSuccessDialogOpen(false)}
        message={successMessage}
      />
      <Dialog
        isDialogOpen={deactivationConfirmation}
        title={t("permanentDeactivation")}
        handleClose={handleClose}
      >
        <Typography paddingY="2rem">{t("deactivation_dialog_text")}</Typography>
        <Box display="flex" gap="1rem" justifyContent="flex-end" mt="1rem">
          <AppButton
            sx={{
              backgroundColor: COLORS.LIGHT_GREY.MAIN,
              color: COLORS.DARK_BLUE.MAIN,
              padding: theme.spacing(1),
            }}
            variant="contained"
            onClick={handleClose}
          >
            {t("cancel")}
          </AppButton>
          <AppButton
            sx={{
              backgroundColor: COLORS.LIGHT_BLUE.LIGHT,
              color: COLORS.DARK_BLUE.MAIN,
              padding: theme.spacing(1),
            }}
            type="button"
            variant="contained"
            onClick={permanentDeactivateHandler}
          >
            {t("submit")}
          </AppButton>
        </Box>
      </Dialog>
    </>
  );
}
