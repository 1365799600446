import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { fromRoot } from "../../pages/appRoutes/appRoutesConst";
import AppLink from "../appLink/appLink";

interface BreadCrumbProps {
  items: { title: string; linkTo?: string }[];
  subtitle?: string;
}

const BreadCrumb: React.FC<BreadCrumbProps> = ({ items, subtitle }) => {
  const theme = useTheme();
  const { spacing } = theme;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        mb: spacing(4),
        display: "flex",
        flexWrap: isMobile ? "wrap" : " nowrap",
      }}
    >
      {items.map(({ title, linkTo }, index) => {
        return (
          <Box key={index} maxWidth={"80%"}>
            {linkTo ? (
              <AppLink style={{ textDecoration: "none" }} to={fromRoot(linkTo)}>
                <Typography
                  color="text.lightGrey.main"
                  variant="h4"
                  component="span"
                  width={"100%"}
                  sx={{
                    ":hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  {title}
                </Typography>
              </AppLink>
            ) : (
              <Typography
                variant="h4"
                component="span"
                sx={{
                  wordWrap: "break-word",
                  maxWidth: "100%",
                }}
              >
                {title}
                {subtitle && (
                  <Typography
                    color="text.lightGrey.main"
                    variant="h4"
                    component="span"
                    width={"100%"}
                    paddingLeft="1rem"
                  >
                    {subtitle}
                  </Typography>
                )}
              </Typography>
            )}
            {items.length > index + 1 ? (
              <Typography
                sx={{ mx: 1 }}
                color="primary.main"
                variant="h4"
                component="span"
              >
                /
              </Typography>
            ) : null}
          </Box>
        );
      })}
    </Box>
  );
};
export default BreadCrumb;
